import { useAuth } from '../../../contexts/AuthContext';
import routes from '../../../constants/Routes';
import { Navigate } from 'react-router-dom';

function AdminRoute({ children, ...rest }) {
  const { currentUser } = useAuth();

  if (!currentUser || !currentUser.isAdmin) {
    return <Navigate to={routes.DASHBOARD} replace={true} />
  }

  return children
}

export default AdminRoute;
