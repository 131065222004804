import moment from "moment";
import routes from "../constants/Routes";

export const getCurrentDateTime = () => {
  // Create a moment object representing the current date and time in UTC
  var d = moment.utc();

  // Return the date and time as a formatted string
  return getFormattedDate(d);
};

function getFormattedDate(d) {
  const format = "YYYY-MM-DD HH:mm:ss";
  return d.format(format);
}

export const getDateFromString = (dateTimeString) => {
  // Use the Date constructor to create a new Date object from the string
  return new Date(dateTimeString);
};

export const getDateTimeStringLocal = (dateTimeString) => {
  // Convert a UTC time string to a Moment object
  const utcTime = moment.utc(dateTimeString);

  // Convert the UTC time to the user's local time
  return getFormattedDate(utcTime.local());
};

export const getDateString = () => {
  const currentDate = new Date();

  const month = currentDate.getMonth() + 1; // months are 0-based
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const mmddyyyy = `${month.toString().padStart(2, "0")}${day
    .toString()
    .padStart(2, "0")}${year}`;

  return mmddyyyy;
};

export const convertDateStringToFriendlyDate = (
  dateString,
  showTime = true
) => {
  // Create a new JavaScript Date object from the date string
  const date = new Date(dateString);

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Get the month and day from the date object
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  // If showTime is false, return just the date without the time
  if (!showTime) {
    if (date.getFullYear() === currentYear) {
      return `${month} ${day}`;
    }
    return `${month} ${day}, ${date.getFullYear()}`;
  }

  // Get hours and minutes from the date object
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert 24-hour time format to 12-hour time format with AM/PM
  const period = hours >= 12 ? "PM" : "AM";
  const hourIn12HourFormat = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // If the date is in the current year, just return the month, day, and time
  if (date.getFullYear() === currentYear) {
    return `${month} ${day}, ${hourIn12HourFormat}:${formattedMinutes} ${period}`;
  }
  // Otherwise, return the month, day, year, and time
  return `${month} ${day}, ${date.getFullYear()}, ${hourIn12HourFormat}:${formattedMinutes} ${period}`;
};

export const calculateDuration = (date1Str, date2Str) => {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);
  let duration = Math.abs(date1 - date2); // difference in milliseconds

  let hours = Math.floor(duration / (1000 * 60 * 60));
  duration %= 1000 * 60 * 60;

  let minutes = Math.floor(duration / (1000 * 60));
  duration %= 1000 * 60;

  let seconds = Math.floor(duration / 1000);

  let result = [];

  if (hours > 0) {
    result.push(hours > 1 ? `${hours} hrs` : `${hours} hr`);
  }

  if (minutes > 0) {
    result.push(minutes > 1 ? `${minutes} mins` : `${minutes} min`);
  }

  if (seconds > 0) {
    result.push(seconds > 1 ? `${seconds} secs` : `${seconds} sec`);
  }

  return result.join(", ");
};

export const mmddyyyyToDate = (mmddyyyy) => {
  const month = mmddyyyy.substr(0, 2);
  const day = mmddyyyy.substr(2, 2);
  const year = mmddyyyy.substr(4);

  return new Date(year, month - 1, day);
};

export const deepCopyArray = (arr) => {
  return arr.map((item) => {
    if (typeof item === "object" && item !== null) {
      if (Array.isArray(item)) {
        // item is an array, so we need to recursively call deepCopyArray
        return deepCopyArray(item);
      } else {
        // item is an object, so we need to use Object.assign to create a copy
        return Object.assign({}, item);
      }
    } else {
      // item is a primitive value, so we can just return it
      return item;
    }
  });
};

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const validateEmail = (email) => {
  // Regular expression to match a valid email address
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(email);
};

export const validatePassword = (password) => {
  // Regular expression to match a password with at least 8 characters,
  // a number, and a special character
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return passwordRegex.test(password);
};

export const isProjectRoute = (pathname) => {
  return (
    pathname.indexOf(routes.PROJECT) > -1 &&
    pathname.indexOf(routes.DASHBOARD_PROJECTS) === -1
  );
};

export const isDashboardProjectRoute = (pathname) => {
  return (
    pathname.indexOf(routes.DASHBOARD_PROJECTS) > -1 &&
    pathname.indexOf(routes.DASHBOARD_RECENT_PROJECTS) === -1
  );
};

export const isRecentProjectRoute = (pathname) => {
  return pathname.indexOf(routes.DASHBOARD_RECENT_PROJECTS) > -1;
};

export const isDashboardRoute = (pathname) => {
  return pathname.indexOf(routes.DASHBOARD) > -1;
};

export const isFooterAvailable = (pathname) => {
  return (
    pathname.indexOf(routes.PROJECT) === -1 &&
    pathname.indexOf(routes.DASHBOARD) === -1 &&
    pathname.indexOf(routes.INTERVIEW) === -1 &&
    pathname.indexOf(routes.PROFILE) &&
    pathname.indexOf(routes.ADD_INTERVIEW) &&
    pathname.indexOf(routes.INITIALIZE_ACCOUNT)
  );
};

export const removeMarkdownCodeBlocks = (text) => {
  const lines = text.split("\n");
  let inCodeBlock = false;
  const cleanedLines = [];

  lines.forEach((line) => {
    if (line.startsWith("```")) {
      inCodeBlock = !inCodeBlock;
    } else if (!inCodeBlock) {
      cleanedLines.push(line);
    }
  });

  // Rejoin the cleaned lines
  return cleanedLines.join("\n");
};

export const removeMarkdownTables = (text) => {
  const lines = text.split("\n");
  const output = [];
  let insideTable = false;

  function isTableStart(line) {
    const words = line.split("|").filter((word) => word.trim().length > 0);
    return words.length > 1;
  }

  function isTableEnd(line) {
    const words = line.split("|").filter((word) => word.trim().length > 0);
    return words.length === 0 || words.length === 1;
  }

  for (const line of lines) {
    if (!insideTable && isTableStart(line)) {
      insideTable = true;
    } else if (insideTable && isTableEnd(line)) {
      insideTable = false;
    }

    if (!insideTable) {
      output.push(line);
    }
  }

  return output.join("\n");
};

export const markdownToHtml = (text) => {
  const lines = text.split("\n");
  let inCodeBlock = false;
  let inTable = false;
  let tableHeader = false;
  let output = [];

  const tableStyle =
    'style="border: 1px solid black; margin: 20px; border-radius: 10px;"';
  const cellStyle = 'style="border: 1px solid black; padding: 10px;"';
  const headerStyle = 'style="background-color: #ddd;"';

  for (const line of lines) {
    if (line.trim() === "```") {
      inCodeBlock = !inCodeBlock;
      output.push(inCodeBlock ? "<pre><code>" : "</code></pre>");
    } else if (!inCodeBlock) {
      if (line.startsWith("|") && line.endsWith("|") && !inTable) {
        inTable = true;
        tableHeader = true;
        output.push(`<table ${tableStyle}>`);
      }

      if (inTable) {
        if (tableHeader) {
          output.push(`<thead ${headerStyle}><tr>`);
          const headers = line.split("|").slice(1, -1);
          headers.forEach((header) =>
            output.push(`<th ${cellStyle}>${header.trim()}</th>`)
          );
          output.push("</tr></thead>");
          tableHeader = false;
        } else if (line.startsWith("|") && line.endsWith("|")) {
          output.push("<tr>");
          const cells = line.split("|").slice(1, -1);
          cells.forEach((cell) =>
            output.push(`<td ${cellStyle}>${cell.trim()}</td>`)
          );
          output.push("</tr>");
        } else {
          output.push("</table>");
          inTable = false;
          output.push(line);
        }
      } else {
        output.push(line);
      }
    } else {
      output.push(line);
    }
  }

  if (inTable) {
    output.push("</table>");
  }

  return output.join("\n");
};

export const deepEqual = (a, b) => {
  // if both a and b are null or undefined and exactly the same
  if (a === b) {
    return true;
  }

  // if a and b are not both objects, return false
  if (
    !(typeof a === "object" && a != null) ||
    !(typeof b === "object" && b != null)
  ) {
    return false;
  }

  let keysA = Object.keys(a);
  let keysB = Object.keys(b);

  // if number of properties is different,
  // objects are not equivalent
  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let key of keysA) {
    // If we've reached a primitive, compare them
    if (typeof a[key] !== "object" || a[key] === null) {
      if (a[key] !== b[key]) {
        return false;
      }
    }
    // Otherwise, compare the sub-objects
    else {
      if (!deepEqual(a[key], b[key])) {
        return false;
      }
    }
  }

  // If we've made it this far, the objects are deeply equivalent
  return true;
};

export const separateNumberedPoints = (input) => {
  // If the input is an array, join it into a string
  let str = Array.isArray(input) ? input.join(" ") : input;

  // Adding newline before each numbered point, while ensuring we don't add unnecessary newlines
  str = str.replace(/(?<!\n)(\d+[.)]\s?)/g, "\n$1");

  return str.trim(); // Remove leading newline if present
};

export const truncateText = (text, length) => {
  let truncated = false;
  if (text.length > length) {
    length = text.lastIndexOf(" ", length);
    text = text.substring(0, length) + "...";
    truncated = true;
  }

  return {
    text,
    truncated,
  };
};

export const getInterviewTitle = (str) => {
  // Trim any trailing whitespace
  str = str.trimRight();

  // Check if the string ends with 'Interview' (case insensitive)
  if (str.toLowerCase().endsWith("interview")) {
    // Remove 'Interview' from the end of the string
    str = str.slice(0, -9).trimRight();
  }

  // Add 'Interview' to the end of the string
  str += " Interview";

  return str;
};

/**
 * Creates and returns a debounced version of the passed function.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay invocation.
 * @param {boolean} [immediate=false] - Call the function on the leading edge instead of the trailing edge.
 * @returns {Function} - The debounced version of the passed function.
 */
export const debounce = (func, wait, immediate = false) => {
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }

  if (typeof wait !== 'number' || isNaN(wait)) {
    throw new TypeError('Expected "wait" to be a valid number');
  }

  let timeout;

  return function executedFunction(...args) {
    const context = this;

    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export const constructFirebaseFunctionURL = (functionName) => {
  const isDevMode = process.env.NODE_ENV === "development";

  const region = process.env.REACT_APP_FIREBASE_REGION || "us-central1"; // default to 'us-central1' if not set
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

  if (!projectId || !functionName) {
    throw new Error(
      "FIREBASE_PROJECT_ID or FIREBASE_FUNCTION_NAME variables are not set."
    );
  }

  // Check if in development mode and return emulator URL
  if (isDevMode) {
    return `http://localhost:5001/${projectId}/${region}/${functionName}`;
  }

  // Production URL
  return `https://${region}-${projectId}.cloudfunctions.net/${functionName}`;
};

export const calculateInterviewTokens = (numQuestions) => {
  const perQuestionRate = 1
  return (numQuestions * perQuestionRate) + 2
}
