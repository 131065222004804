// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
import LoggerService from "../components/Logger/loggerService";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  ...(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID && { measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID })
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app)
LoggerService.log(process.env.NODE_ENV)

if (process.env.NODE_ENV === 'development') {
  const functions = getFunctions(app);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { getFunctions, httpsCallable, app, auth }