import { useAuth } from "../../../contexts/AuthContext";
import routes from "../../../constants/Routes";
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, ...rest }) {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to={routes.SIGN_IN} replace={true} />
  } else if (!currentUser.emailVerified) {
    return <Navigate to={routes.EMAIL_NOT_VERIFIED} replace={true} />
  } else if (currentUser.protected && currentUser.protected.waitlistEnabled){
    return <Navigate to={routes.WAITLIST} replace={true} />
  } 
  // else if (currentUser.protected && !currentUser.protected.initialized){
  //   return <Navigate to={routes.INITIALIZE_ACCOUNT} replace={true} />
  // }

  return children
}

export default PrivateRoute;
