import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase/firebase";
import { GoogleAuthProvider } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { isUserAdmin } from "../firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { app } from "../firebase";
import { getApps, getApp } from "firebase/app";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [userTokens, setUserTokens] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser) {
      let firebaseApp;
      if (!getApps().length) {
        firebaseApp = app;
      } else {
        firebaseApp = getApp(); // if already initialized, use that one
      }
      const db = getFirestore(firebaseApp);

      // subscribe to the total available tokens count query
      const tokensQuery = query(
        collection(db, `users/${currentUser.uid}/tokens`),
        where("processed", "==", false),
        where("locked", "==", false)
      );
      const unsubscribeTokens = onSnapshot(tokensQuery, (querySnapshot) => {
        setUserTokens(querySnapshot.size);
      });

      // subscribe to get the user info
      const userRef = doc(db, "users", currentUser.uid);
      const unsubscribeUser = onSnapshot(userRef, (document) => {
        if (document.exists()) {
          const data = document.data();

          if (data) {
            setCurrentUser({ ...currentUser, ...data });
          }
        }
      });

      return () => {
        unsubscribeTokens();
        unsubscribeUser();
      };
    }
  }, [userLoggedIn]);

  async function initializeUser(user) {
    if (user) {
      // check if user is admin
      const isAdmin = await isUserAdmin();

      setCurrentUser({ ...user, isAdmin: isAdmin });

      // check if provider is email and password login
      const isEmail = user.providerData.some(
        (provider) => provider.providerId === "password"
      );
      setIsEmailUser(isEmail);

      // check if the auth provider is google or not
      const isGoogle = user.providerData.some(
        (provider) => provider.providerId === GoogleAuthProvider.PROVIDER_ID
      );
      setIsGoogleUser(isGoogle);

      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }

    setLoading(false);
  }

  const value = {
    userLoggedIn,
    isEmailUser,
    isGoogleUser,
    currentUser,
    setCurrentUser,
    loading,
    setLoading,
    userTokens,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
