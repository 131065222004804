import React, { useContext, useState, useEffect } from "react";
import { getAllUserProjects, getAppConfig } from "../firebase/firestore";
import { useAuth } from "./AuthContext";
import { toast } from 'react-toastify'

const AppContext = React.createContext();

const defaultToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export function useApp() {
  return useContext(AppContext);
}

export function AppProvider({ children }) {
  const { currentUser, userLoggedIn } = useAuth();

  const [config, setConfig] = useState({});
  const [projects, setProjects] = useState([]);
  const [projectsDirty, setProjectsDirty] = useState(true);
  const [currentProjectInfo, setCurrentProjectInfo] = useState({});
  const [showRootScrollbar, setShowRootScrollbar] = useState(true);

  const showToast = (text, type, options = {}) => {
    const combinedOptions = { ...defaultToastOptions, ...options };
    switch (type) {
        case 'error':
            toast.error(text, combinedOptions);
            break;
        case 'success':
            toast.success(text, combinedOptions);
            break;
        case 'info':
            toast.info(text, combinedOptions);
            break;
        case 'warn':
            toast.warn(text, combinedOptions);
            break;
        default:
            toast(text, combinedOptions);
    }
};

  useEffect(() => {

    // fetch the app config
    getConfig()
  }, [])

  useEffect(() => {
    if (userLoggedIn) {
      const fetchData = async () => {
        const data = await getAllUserProjects(currentUser);
        setProjects(prevValue => data);
      };

      fetchData();
    }
    else{
      setProjects([]);
    }
  }, [userLoggedIn]);

  async function getConfig(){
    try {
      const config = await getAppConfig()
      if(config){
        setConfig(config)
      }
    } catch(error) {
      console.error('Error in fetching application data.')
    }
  }

  const value = {
    config,
    projects,
    setProjects,
    projectsDirty,
    setProjectsDirty,
    currentProjectInfo,
    setCurrentProjectInfo,
    showToast,
    showRootScrollbar,
    setShowRootScrollbar
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
