const routes = {
    ADMIN : "/admin",

    REGISTER : "/register",
    SIGN_IN : "/signin",
    
    DASHBOARD : "/dashboard",
    DASHBOARD_HOME: '',

    HOME : "/",
    ABOUT : "/about",
    BENEFITS : "/benefits",
    HOW_IT_WORKS : "/how-it-works",
    SERVICE_TERMS : "/terms-of-service",
    PRIVACY_POLICY : "/privacy-policy",
    COOKIE_POLICY : "/cookie-policy",
    FAQ : "/faq",
    ACCOUNT : "/account",
    FORGET_PASSWORD : "/forget-password",
    LEGAL_TERMS : "/legal-terms",    
    PROFILE : "/profile",
    PROJECT : "/project",
    EMAIL_NOT_VERIFIED : "/email-not-verified",
    WAITLIST : "/waitlist",
    PRODUCT_OVERVIEW : "/product-overview",
    INTERVIEW : "/interview",
    PRICING : "/pricing",
    SUPPORT : "/support",
    REFUND : "/refund-policy",
    ADD_INTERVIEW: "/add-interview",
    INITIALIZE_ACCOUNT: "/initialize-account",
    PRINT_REPORT: "/print-report",


    BLOG : "/blog",
    BLOG_POST : "/blog/post",
    

    ANY : "*",
}

export default routes