import React, { useState, useEffect } from 'react';
import CookieSettings from '../CookieSettings';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import routes from '../../../constants/Routes';

const CookiePopup = () => {
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const [showPopup, setShowPopup] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setShowPopup(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setShowPopup(false);
    };

    const openSettings = () => {
        setShowSettings(true);
    };

    const closeSettings = () => {
        setShowSettings(false);
    };

    const navigateToPolicyPage = () => {
        navigate(routes.COOKIE_POLICY)
    }

    if (!currentUser || !showPopup) {
        return null;
    }

    return (
        <div className="fixed z-50 bottom-0 left-0 right-0 bg-gray-800 text-white py-2 px-4 flex items-center justify-between border-t border-t-light-background">
            <div className='flex flex-col gap-1 font-medium text-sm'>
                <p>We use cookies to enhance your browsing experience, understand how our site is used, and tailor ads. By clicking 'I Understand', you agree to our use of cookies for improved website functionality and personalized content and ads.</p>
                <p>Click 'Cookie Settings' to manage your preferences.</p>
                <div className='flex flex-col md:flex-row gap-3'>
                    <button onClick={acceptCookies} className="bg-white border text-gray-800 font-medium w-full md:w-44 px-4 py-2 hover:text-white hover:bg-gray-800 hover:border-white rounded-xl">I Understand</button>
                    <button onClick={openSettings} className="bg-white border text-gray-800 font-medium w-full md:w-44 px-4 py-2 hover:text-white hover:bg-gray-800 hover:border-white rounded-xl">Cookie Settings</button>
                    <button onClick={navigateToPolicyPage} className="bg-white border text-gray-800 font-medium w-full md:w-44 px-4 py-2 hover:text-white hover:bg-gray-800 hover:border-white rounded-xl">Cookie Policy</button>
                </div>
            </div>

            {showSettings && <CookieSettings onClose={closeSettings} />}
        </div>
    );
};

export default CookiePopup;
