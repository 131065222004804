import React, { useState, useEffect } from 'react';

const CookieSettings = ({ onClose }) => {
    const [selectedTab, setSelectedTab] = useState('Strictly Necessary');
    const [cookies, setCookies] = useState({
        'Strictly Necessary': true,
        'Analytics': false,
        'Performance': false,
        'Advertising': false,
    });

    useEffect(() => {
        const storedCookies = localStorage.getItem('cookies');
        if (storedCookies) {
          setCookies(JSON.parse(storedCookies));
        }
      }, []);

    const tabs = [
        'Strictly Necessary',
        'Analytics',
        'Performance',
        'Advertising',
        'More Information',
    ];

    const tabDescriptions = {
        'Analytics': 'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site.',
        'Performance': 'These cookies enable the website to provide enhanced functionality and personalization.',
        'Advertising': 'These cookies may be set through our site by our advertising partners.',
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleCheckboxChange = (event) => {
        const newCookies = { ...cookies, [event.target.name]: event.target.checked };
        setCookies(newCookies);
        localStorage.setItem('cookies', JSON.stringify(newCookies));
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'More Information':
                return (
                    <div className='flex flex-col gap-3'>
                        <p>Cookies are small text files that can be used by websites to make a user's experience more efficient.</p>
                        <p>The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.</p>
                        <p>This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.</p>
                    </div>
                );
                case 'Strictly Necessary':
                return (
                    <div className='flex flex-col'>
                        <p>These cookies are essential for the website to function and cannot be switched off in our systems.</p>
                        <div className="flex items-center mt-4">
                            <input
                                type="checkbox"
                                id={'Strictly Necessary'}
                                name={'Strictly Necessary'}
                                checked
                                disabled
                                className="mr-2"
                            />
                            <label htmlFor={selectedTab}>Enable {selectedTab} Cookies</label>
                        </div>
                    </div>
                );
            default:
                return (
                    <div>
                        <p>{tabDescriptions[selectedTab]}</p>
                        <div className="flex items-center mt-4">
                            <input
                                type="checkbox"
                                id={selectedTab}
                                name={selectedTab}
                                checked={cookies[selectedTab]}
                                disabled = {selectedTab === 'Strictly Necessary'}
                                onChange={handleCheckboxChange}
                                className="mr-2"
                            />
                            <label htmlFor={selectedTab}>Enable {selectedTab} Cookies</label>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="scrollbar-thin scrollbar-webkit overflow-auto bg-white mx-2 p-6 rounded-2xl flex flex-col w-[600px] h-[350px] text-black">
                <div className="flex flex-grow">
                    <div className="w-1/3 pr-4 border-r">
                        {tabs.map(tab => (
                            <button
                                key={tab}
                                onClick={() => handleTabClick(tab)}
                                className={`block w-full text-left px-4 py-2 rounded font-semibold text-sm ${selectedTab === tab ? 'bg-brand-primary text-white' : ''}`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <div className="w-2/3 pl-4 pt-4 font-medium text-sm">
                        {renderTabContent()}
                    </div>
                </div>
                <button onClick={onClose} className="text-brand-primary border border-brand-primary hover:bg-brand-primary hover:text-white m-1 w-24 self-end px-4 py-1 font-semibold rounded">Close</button>
            </div>
        </div>
    );
};

export default CookieSettings;
