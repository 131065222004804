import "./App.css";

import { useEffect, lazy, Suspense } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import routes from "./constants/Routes";
import { AuthProvider } from "./contexts/AuthContext";
import { AppProvider } from "./contexts/AppContext";
import { ToastContainer } from "react-toastify";
import { useApp } from "./contexts/AppContext";

import PublicRoute from "./components/route-components/public-route";
import PrivateRoute from "./components/route-components/private-route";
import AdminRoute from "./components/route-components/admin-route";
import CookiePopup from "./components/Cookies/CookiePopup";

const Home = lazy(() => import("./components/home"));
const About = lazy(() => import("./components/about"));
const Benefits = lazy(() => import("./components/benefits"));
const HowItWorks = lazy(() => import("./components/how-it-works"));
const Login = lazy(() => import("./components/auth/Login"));
const Register = lazy(() => import("./components/auth/Register"));
const Header = lazy(() => import("./components/header"));
const Footer = lazy(() => import("./components/footer/Footer"));
// const Dashboard = lazy(() => import("./components/dashboard"));
// const DashboardHome = lazy(() =>
//   import("./components/dashboard/dashboard-home")
// );
const DashboardSwitcher = lazy(() =>
  import("./components/dashboard/dashboard-switcher")
);
const ForgetPassword = lazy(() => import("./components/auth/ForgetPassword"));
const Profile = lazy(() => import("./components/profile"));
const Project = lazy(() => import("./components/project"));
const ProjectReport = lazy(() => import("./components/project/report"));
const SelectedReport = lazy(() =>
  import("./components/project/report/selected-report")
);
const EmailNotVerified = lazy(() => import("./components/email-not-verified"));
const FaqList = lazy(() => import("./components/faq"));
const Waitlist = lazy(() => import("./components/waitlist"));
const PrivacyPolicy = lazy(() => import("./components/legal/privacy-policy"));
const TermsOfService = lazy(() =>
  import("./components/legal/terms-of-service")
);
const CookiePolicy = lazy(() => import("./components/Cookies/cookie-policy"));
const Admin = lazy(() => import("./components/admin"));
const Interview = lazy(() => import("./components/interview"));
const Pricing = lazy(() => import("./components/pricing"));
const CustomerSupport = lazy(() => import("./components/customer-support"));
const RefundPolicy = lazy(() => import("./components/refund-policy"));
const BlogHome = lazy(() => import("./components/blog/blog-home"));
const BlogPost = lazy(() => import("./components/blog/blog-post"));
const AddInterview = lazy(() => import("./components/add-interview"));
const InitializeAccount = lazy(() => import("./components/initialize-account"));
const PrintReport = lazy(() =>
  import("./components/project/report/print-report")
);

function Layout({ children }) {
  const location = useLocation();

  useEffect(() => {
    const removeStyle = setTimeout(() => {
      const element = document.querySelector(
        ".scrollbar-thin.scrollbar-webkit"
      );

      if (element) {
        element.removeAttribute("style");
      }
    }, 100); // 100 milliseconds delay

    // Cleanup function to clear the timeout if the component unmounts before the timeout finishes
    return () => clearTimeout(removeStyle);
  }, [location]);

  return (
    <section className="flex flex-col h-screen">{children}</section>
  );
}

function App() {
  useEffect(() => {
    const storedCookies = localStorage.getItem("cookies");
    if (!storedCookies) {
      const defaultCookies = {
        Analytics: true,
        Performance: true,
        Advertising: true,
      };
      localStorage.setItem("cookies", JSON.stringify(defaultCookies));
    }
  }, []);

  const routesArray = [
    {
      path: routes.ANY,
      element: (
        <PublicRoute>
          <Home />
        </PublicRoute>
      ),
    },
    {
      path: routes.HOME,
      element: (
        <PublicRoute>
          <Home />
        </PublicRoute>
      ),
    },
    {
      path: routes.ABOUT,
      element: (
        <PublicRoute>
          <About />
        </PublicRoute>
      ),
    },
    {
      path: routes.BENEFITS,
      element: (
        <PublicRoute>
          <Benefits />
        </PublicRoute>
      ),
    },
    {
      path: routes.HOW_IT_WORKS,
      element: (
        <PublicRoute>
          <HowItWorks />
        </PublicRoute>
      ),
    },
    {
      path: routes.SERVICE_TERMS,
      element: (
        <PublicRoute>
          <TermsOfService />
        </PublicRoute>
      ),
    },
    {
      path: routes.PRIVACY_POLICY,
      element: (
        <PublicRoute>
          <PrivacyPolicy />
        </PublicRoute>
      ),
    },
    {
      path: routes.COOKIE_POLICY,
      element: (
        <PublicRoute>
          <CookiePolicy />
        </PublicRoute>
      ),
    },
    {
      path: routes.SIGN_IN,
      element: (
        <PublicRoute>
          <Login />
        </PublicRoute>
      ),
    },
    {
      path: routes.REGISTER,
      element: (
        <PublicRoute>
          <Register />
        </PublicRoute>
      ),
    },
    {
      path: routes.FORGET_PASSWORD,
      element: (
        <PublicRoute>
          <ForgetPassword />
        </PublicRoute>
      ),
    },
    {
      path: routes.FAQ,
      element: (
        <PublicRoute>
          <FaqList />
        </PublicRoute>
      ),
    },
    {
      path: routes.DASHBOARD,
      element: (
        <PrivateRoute>
          <DashboardSwitcher />
        </PrivateRoute>
      ),
      // children: [{ path: routes.DASHBOARD_HOME, element: <DashboardHome /> }],
    },
    {
      path: routes.ADMIN,
      element: (
        <AdminRoute>
          <Admin />
        </AdminRoute>
      ),
    },
    {
      path: routes.PROFILE,
      element: (
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      ),
    },
    {
      path: `${routes.PROJECT}/:projectId`,
      element: (
        <PrivateRoute>
          <Project />
        </PrivateRoute>
      ),
    },

    {
      path: `${routes.PROJECT}/:projectId/report`,
      element: (
        <PrivateRoute>
          <ProjectReport />
        </PrivateRoute>
      ),
      children: [
        {
          path: `session/:sessionId`,
          element: <SelectedReport />,
        },
      ],
    },
    {
      path: `${routes.PRINT_REPORT}/:projectId/:sessionId`,
      element: (
        <PrivateRoute>
          <PrintReport />
        </PrivateRoute>
      ),
    },
    {
      path: `${routes.INTERVIEW}/:projectId`,
      element: (
        <PrivateRoute>
          <Interview />
        </PrivateRoute>
      ),
    },
    {
      path: routes.ADD_INTERVIEW,
      element: (
        <PrivateRoute>
          <AddInterview />
        </PrivateRoute>
      ),
    },
    {
      path: routes.INITIALIZE_ACCOUNT,
      element: (
        <PublicRoute>
          <InitializeAccount />
        </PublicRoute>
      ),
    },
    {
      path: routes.EMAIL_NOT_VERIFIED,
      element: (
        <PublicRoute>
          <EmailNotVerified />
        </PublicRoute>
      ),
    },
    {
      path: routes.WAITLIST,
      element: (
        <PublicRoute>
          <Waitlist />
        </PublicRoute>
      ),
    },
    // {
    //   path: routes.PRICING,
    //   element: (
    //     <PublicRoute>
    //       <Pricing />
    //     </PublicRoute>
    //   ),
    // },
    {
      path: routes.SUPPORT,
      element: (
        <PublicRoute>
          <CustomerSupport />
        </PublicRoute>
      ),
    },
    {
      path: routes.REFUND,
      element: (
        <PublicRoute>
          <RefundPolicy />
        </PublicRoute>
      ),
    },

    {
      path: routes.BLOG,
      element: (
        <PublicRoute>
          <BlogHome />
        </PublicRoute>
      ),
    },
    {
      path: `${routes.BLOG_POST}/:slug`,
      element: (
        <PublicRoute>
          <BlogPost />
        </PublicRoute>
      ),
    },
  ];

  let routesElement = useRoutes(routesArray);
  return (
    <Layout>
      <AuthProvider>
        <AppProvider>
          <Header></Header>
          <ScrollbarWrapper>
            <Suspense fallback={<div className="w-full h-full"></div>}>
              <div className="w-full flex-grow flex flex-col">
                {routesElement}
              </div>
            </Suspense>
            <Footer></Footer>
          </ScrollbarWrapper>
          <ToastContainer />
        </AppProvider>
        <CookiePopup />
      </AuthProvider>
    </Layout>
  );
}

const ScrollbarWrapper = ({ children }) => {
  const { showRootScrollbar } = useApp();
  return (
    <div className={`flex flex-col h-full mt-12 bg-light-background ${showRootScrollbar ? 'scrollbar-thin scrollbar-webkit overflow-auto' : 'overflow-hidden hide-scrollbar'} `}>
      {children}
    </div>
  );
};

export default App;
