import { app } from "./firebase";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { uuidv4 } from "@firebase/util";
import { getCurrentDateTime } from "../helper/helper";
import { getFunctions, httpsCallable } from "firebase/functions";
import LoggerService from "../components/Logger/loggerService";

const db = getFirestore(app);

export const getAppConfig = async () => {
  const configSnap = await getDoc(doc(db, "app", "config"));

  const data = configSnap.data();
  if (data) {
    return { ...configSnap.data() };
  } else {
    return undefined;
  }
};

export const addNewUser = async (user) => {
  try {
    // call a server function to add a new user
    const functions = getFunctions();
    const addUser = httpsCallable(functions, "addUser");

    const data = {
      email: user.email,
    };

    const result = await addUser(data);
    LoggerService.log(result);
    return result;
  } catch (e) {
    LoggerService.error("Error adding document: ", e);
  }
};

export const getUserInfo = async (uid) => {
  const userSnap = await getDoc(doc(db, "users", uid));

  const data = userSnap.data();
  if (data) {
    return { ...userSnap.data(), id: userSnap.id };
  } else {
    return undefined;
  }
};

export const getAllUserProjects = async (user) => {
  let docs = [];
  try {
    const q = query(
      collection(db, `users/${user.uid}/projects`),
      orderBy("LastModified", "desc")
    );
    const projectsSnap = await getDocs(q);
    projectsSnap.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (e) {
    LoggerService.error("Error fetching projects: ", e);
  }
};

export const addNewProject = async (uid, project) => {
  try {
    const parentRef = doc(db, "users", uid);
    const projectId = uuidv4();
    await setDoc(doc(parentRef, "projects", projectId), project);
    return projectId;
  } catch (e) {}
};

export const makeProjectCopy = async (interviewId) => {
  try {
    const functions = getFunctions();
    const copyInterview = httpsCallable(functions, "copyInterview");

    const data = {
      interviewId: interviewId,
    };

    const result = await copyInterview(data);
    LoggerService.log(result);
    return result;
  } catch (e) {
    LoggerService.error("Error adding document: ", e);
  }
};

export const getInterviewSession = async (uid, projectId, sessionId) => {
  const sessionSnap = await getDoc(
    doc(db, "users", uid, "projects", projectId, "LastSessions", sessionId)
  );

  const data = sessionSnap.data();
  if (data) {
    return { ...sessionSnap.data(), id: sessionSnap.id };
  } else {
    return undefined;
  }
};

export const getAllInterviewSessions = async (uid, projectId) => {
  let sessions = [];
  try {
    const sessionsSnap = await getDocs(
      collection(db, `users/${uid}/projects/${projectId}/LastSessions`)
    );
    sessionsSnap.forEach((doc) => {
      sessions.push({ ...doc.data(), id: doc.id });
    });
    return sessions;
  } catch (e) {
    LoggerService.error("Error fetching sessions: ", e);
  }
};

export const getAllSessionQuestions = async (uid, projectId, sessionId) => {
  let questions = [];
  try {
    const questionsSnap = await getDocs(
      collection(
        db,
        `users/${uid}/projects/${projectId}/LastSessions/${sessionId}/Questions`
      )
    );
    questionsSnap.forEach((doc) => {
      questions.push({ ...doc.data(), id: doc.id });
    });
    return questions;
  } catch (e) {
    LoggerService.error("Error fetching questions: ", e);
  }
};

export const removeProject = async (uid, projectId) => {
  return deleteDoc(doc(db, "users", uid, "projects", projectId));
};

export const getProjectById = async (uid, projectId) => {
  const projectSnap = await getDoc(
    doc(db, "users", uid, "projects", projectId)
  );

  const data = projectSnap.data();
  if (data) {
    return { ...projectSnap.data(), id: projectSnap.id };
  } else {
    return undefined;
  }
};

export const updateProjectName = async (uid, projectId, newName) => {
  try {
    const projectRef = doc(db, "users", uid, "projects", projectId);
    return setDoc(
      projectRef,
      {
        Name: newName,
      },
      { merge: true }
    );
  } catch (e) {}
};

export const updateProjectProperties = async (uid, projectId, obj) => {
  try {
    if (obj) {
      obj = { ...obj, LastModified: getCurrentDateTime() };

      const projectRef = doc(db, "users", uid, "projects", projectId);

      await setDoc(projectRef, obj, { merge: true });
      return projectId;
    } else {
      throw new Error("No key to match!");
    }
  } catch (e) {}
};

export const ProjectDataKey = {
  INTERVIEW_STARTED: "INTERVIEW_STARTED",
  CURRENT_QUESTIONS: "CURRENT_QUESTIONS",
  LAST_SESSION: "LAST_SESSION",
  INTERVIEW_START_TIME: "INTERVIEW_START_TIME",
};

export const updateProjectData = async (uid, projectId, dataKey, data) => {
  try {
    let obj;
    switch (dataKey) {
      case ProjectDataKey.INTERVIEW_STARTED:
        obj = {
          InterviewStarted: data,
        };
        break;
      case ProjectDataKey.CURRENT_QUESTIONS:
        obj = {
          CurrentQuestions: data,
        };
        break;
      case ProjectDataKey.LAST_SESSION:
        obj = {
          LastSession: data,
        };
        break;
      case ProjectDataKey.INTERVIEW_START_TIME:
        obj = {
          InterviewStartTime: data,
        };
        break;

      default:
        // do nothing
        break;
    }

    if (obj) {
      obj = { ...obj, LastModified: getCurrentDateTime() };

      const projectRef = doc(db, "users", uid, "projects", projectId);

      return setDoc(projectRef, obj, { merge: true });
    } else {
      throw new Error("No key to match!");
    }
  } catch (e) {}
};

export const updateJoyrideFlags = async (uid, data) => {
  const {
    dashboardOverviewActive,
    createProjectOverviewActive,
    projectOverviewActive,
    interviewOverviewActive,
    reportsOverviewActive,
  } = data;

  let flags = null;

  if (
    dashboardOverviewActive !== undefined ||
    createProjectOverviewActive !== undefined ||
    projectOverviewActive !== undefined ||
    interviewOverviewActive !== undefined ||
    reportsOverviewActive !== undefined
  ) {
    flags = {
      ...(dashboardOverviewActive !== undefined && { dashboardOverviewActive }),
      ...(createProjectOverviewActive !== undefined && {
        createProjectOverviewActive,
      }),
      ...(projectOverviewActive !== undefined && { projectOverviewActive }),
      ...(interviewOverviewActive !== undefined && { interviewOverviewActive }),
      ...(reportsOverviewActive !== undefined && { reportsOverviewActive }),
    };
  }

  if (flags) {
    try {
      const userRef = doc(db, "users", uid);
      return setDoc(userRef, flags, { merge: true });
    } catch (e) {}
  }
};

export const fetchUserFlag = async (uid, flagType) => {
  const userSnap = await getDoc(doc(db, "users", uid));
  const data = userSnap.data();
  if (data) {
    return data[flagType] ?? false;
  }

  return false;
};

export const updateUserFlag = async (uid, flagType, data) => {
  let flagObj = {
    [flagType]: data,
  };
  try {
    const userRef = doc(db, "users", uid);
    return setDoc(userRef, flagObj, { merge: true });
  } catch (e) {}
};

// export const submitUserFeedback = async (uid, subject, description) => {
//   let obj = {
//     "feedback": {
//       'subject': subject,
//       'description': description
//     },
//   };
//   try {
//     const userRef = doc(db, "users", uid);
//     return setDoc(userRef, obj, { merge: true });
//   } catch (e) {}
// };

export const getBlogPosts = async () => {
  let posts = [];
  try {
    const q = query(collection(db, "posts"), orderBy("publishedDate", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      posts.push({
        ...doc.data(),
        publishedDate: doc.data().publishedDate.toDate(),
        id: doc.id,
      });
    });
    return posts;
  } catch (e) {
    LoggerService.error("Error fetching posts: ", e);
  }
};

export const getPost = async (slug) => {
  try {
    const q = query(collection(db, "posts"), where("slug", "==", slug));

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length) {
      return {
        ...querySnapshot.docs[0].data(),
        publishedDate: querySnapshot.docs[0].data().publishedDate.toDate(),
        id: querySnapshot.docs[0].id,
      };
    } else {
      return undefined;
    }
  } catch (e) {
    LoggerService.error("Error fetching post: ", e);
  }
};
