import { auth } from "./firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

import { addNewUser } from "./firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export const isUserAdmin = async () => {
  const idTokenResult = await auth.currentUser.getIdTokenResult()
  if (!!idTokenResult.claims.admin){
    return true;
  }

  return false
}

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  try {
    // call a server function to add a new user
    const functions = getFunctions();
    const createUserWithEmail = httpsCallable(functions, "createUserWithEmail");

    const data = {
      email: email,
      password: password,
      redirect: `${window.location.origin}/dashboard`
    };

    return createUserWithEmail(data);

  } catch (e) {
    console.error("Error creating new user: ", e);
  }

  // return createUserWithEmailAndPassword(auth, email, password);
};

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;
  addNewUser(user);
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const updateUserProfile = (displayName) => {
  return updateProfile(auth.currentUser, {
    displayName: displayName
  });
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/dashboard`,
  });
};

export const getIdToken = async () => {
  try{
    const idToken = await auth.currentUser.getIdToken(true)
    return idToken
  } catch(error) {
    return false
  }
}
