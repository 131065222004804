class LoggerService {
    static log(message, ...optionalParams) {
      if (process.env.NODE_ENV === "development") {
        console.log(message, ...optionalParams);
      }
    }
  
    static error(message, ...optionalParams) {
      if (process.env.NODE_ENV === "development") {
        console.error(message, ...optionalParams);
      }
    }
  }
  
  export default LoggerService;